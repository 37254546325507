import React, { useEffect } from 'react';
import { Html5QrcodeScanner } from "html5-qrcode";

const QrCodeScanner = () => {
  useEffect(() => {
    const scanner = new Html5QrcodeScanner(
      "reader", { fps: 10, qrbox: { width: 250, height: 250 } }, false
    );
    debugger

    scanner.render(successCallback, errorCallback);

    function successCallback(decodedText, decodedResult) {
      alert(`QR Code detected: ${decodedText}`);
      scanner.clear();
    }

    function errorCallback(error) {
      console.warn(`QR Code scan error: ${error}`);
    }

    return () => {
      scanner.clear().catch(error => console.error("Failed to clear scanner.", error));
    };
  }, []);

  return <div id="reader" style={{ width: "500px" }}></div>;
}

export default QrCodeScanner;
